import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import VisibilitySensor from "react-visibility-sensor";
import consultPartner from "../../images/aws-partner-tier-badge.svg";

function Overview({ locale }) {
  const partnerdata = useStaticQuery(graphql`
    query {
      allStrapiPartner {
        edges {
          node {
            locale
            partnerId
            heading
            description
            title
            why {
              title
              bulletpoints {
                des
                name
              }
            }
            logo {
              url
            }
          }
        }
      }
    }
  `).allStrapiPartner.edges;

  const filterData = partnerdata.filter((node) => node.node.locale === locale);

  return (
    <>
      {filterData.map((item, index) => (
        <VisibilityWrap sectionId={item.node.partnerId}>
          <div className="partnerHotspot" key={index} id={item.node.partnerId}>
            <div className="partner">
              <div className="partner_container">
                <div className="row">
                  <div className="partnerLeftSection">
                    <div className="partnerTitle">{item.node.title}</div>
                    <div className="partnerHeading">{item.node.heading}</div>
                    <div className="partnerDescription">
                      {item.node.description}
                    </div>
                  </div>
                  <div className="partnerRightSection">
                    <div
                      className={
                        item.node.title === "Google Cloud"
                          ? "d-flex justify-content-center"
                          : ""
                      }
                    >
                      {!["AWS", "AWS Public Support Statement"].includes(
                        item.node.title
                      ) && (
                        <img
                          src={item.node.logo.url}
                          alt={item.node.title}
                          loading="lazy"
                          className={`awsImg
                    ${
                      ["Microsoft Azure", "Vmware"].includes(item.node.title)
                        ? "platformLogoLg"
                        : item.node.title === "Google Cloud"
                        ? "platformLogoGCP"
                        : ""
                    }`}
                        />
                      )}
                    </div>

                    <div className="aws_par_wrap">
                      {["AWS", "AWS Public Support Statement"].includes(
                        item.node.title
                      ) && (
                        <>
                          <div className="aws_inside">
                            <img src={consultPartner} alt="consultPartner" />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="solSection">
              <div className="solWrap">
                <div className="solsecHead">
                  <h4 className="smallHeading">{`${item.node.why.title} ?`}</h4>
                </div>
                <div className="solsecBody">
                  <div className="solbodyWrap">
                    <div className="partnercol">
                      {item.node.why.bulletpoints.map((val, index) => {
                        return (
                          <div className="solcolWrap" key={index}>
                            <div className="inside_social_wrap">
                              <div className="outside_wrap">
                                <div className="partnerListTitle">
                                  {val.name}
                                </div>
                              </div>
                              <div className="outside_wrap">
                                <div className="solName">{val.des}</div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </VisibilityWrap>
      ))}
    </>
  );
}

const VisibilityWrap = ({ sectionId, children }) => {
  const [active, setActive] = useState(false);
  const [screenHeight, setScreenHeight] = useState(0);

  setTimeout(() => {
    setActive(true);
  }, 1200);

  useEffect(() => {
    const handler = () => {
      setActive(false);
      setTimeout(() => {
        setActive(true);
      }, 1200);
    };

    setScreenHeight(window.innerHeight);

    const upperClass = document.getElementsByClassName(
      "platform_upper_class"
    )[0];

    upperClass.addEventListener("click", handler);

    return () => {
      upperClass.removeEventListener("click", handler);
    };
  });

  return (
    <VisibilitySensor
      active={active}
      partialVisibility={true}
      delayedCall={true}
      offset={{
        top: screenHeight > 0 ? screenHeight / 2 : 300,
        bottom: screenHeight > 0 ? screenHeight / 2 : 300,
      }}
      onChange={(isVisible) => {
        if (isVisible && sectionId !== "aws") {
          navigate(`/cloud-platforms/#${sectionId}`);
        }
      }}
    >
      {children}
    </VisibilitySensor>
  );
};

export default Overview;
